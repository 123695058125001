.selectButtonGroup-container {
  padding: 4px;
  @media (max-width: 1023px) {
    padding: 0;
  }
}

.selectButtonGroup-filter {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;

  padding: 10px;
  @media (max-width: 1023px) {
    padding: 10px 0;
    gap: 10px;
  }
}

.selectButtonGroup-filter span {
  padding-right: 10px;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.selectButtonGroup-tab {
  padding: 11px 22px;
  cursor: pointer;
  background: #f5f8fa;
  border: 1px solid #d8e2eb;
  border-radius: 0px 2px 2px 0px;
}

.selectButtonGroup-tab.isSelected {
  color: var(--base-blue);
  background: #ffffff;
  box-shadow: 0px 4px 10px #274f841a;
  border-radius: var(--border-radius) 0px 0px 4px;
}
