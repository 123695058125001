.printBtn {
  padding: 0 10px;
  height: 50px;
  width: 130px;
  background-color: white;
  color: var(--base-blue);
  font-size: 18px;
  font-family: 'ArticulatCF-Bold';
  border: 2px solid var(--lighter-blue-96);
  border-radius: var(--element);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: all 0.4s ease-in-out;
}

.printBtn img {
  width: 20px;
  height: 20px;
}

.printBtn:hover {
  background-color: var(--base-blue);
  color: white;
  border: 2px solid;
}

.save_pdf_btn.printBtn {
  margin-bottom: 14px;
  color: var(--base-blue);
}

.save_pdf_btn.printBtn:hover {
  color: white;
}

.vcrComments {
  width: 35vw;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .save_pdf_btn.printBtn {
    margin-bottom: 4px;
    color: var(--base-blue);
    height: 46px;
  }
}

@media screen and (max-width: 767px) {
  .vcrComments {
    padding-top: 20px;
    width: 100%;
  }
}
