.flagsBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 280px;
  pointer-events: none;
}

.flagsBox-container {
  font-family: 'Fontawesome webfont', sans-serif;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  outline: none;
  padding: 20px;
  color: #fff;
  font-size: 14px;
  background: var(--darker-blue-17);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 15px var(--darker-neutral-45);
  pointer-events: auto;
}

.flagsBox.light .flagsBox-container {
  background-color: white;
}

.flagsBox-container.expand {
  width: 100%;
  height: 100%;
  box-shadow: unset;
  padding: 0;
}

.flagsBox-title {
  font-size: 21px;
  margin-bottom: 15px;
}

.flagsBox hr {
  width: 100%;
  margin-right: 0;
  background: #3a4679;
  height: 1px;
  border: none;
  margin-top: 0;
  margin-bottom: 16px;
  width: 86%;
  margin-left: auto;
  margin-right: auto;
}

.flagsBox hr:last-child {
  display: none;
}

.flagsBox-flag-list {
  max-height: inherit;
  overflow-y: scroll;
}

.flagsBox-loading {
  display: flex;
  height: 100%;
  width: 100%;
}

.flagsBox-not-found {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
}

.flagsBox-button {
  margin-left: 10px;
  background: var(--darker-blue-17);
  box-shadow: 0 2px 10px #22437480;
  pointer-events: auto;
}

.flagsBox-button:hover {
  background-color: var(--darker-blue-43);
}

.flagsBox-button img {
  width: 30px;
  height: 30px;
}

.badge-icon {
  position: relative;
  top: -8px;
  left: 24px;
  min-width: 25px;
  text-align: center;

  font-size: 14px;
  font-family: 'ArticulatCF-Bold';

  border-radius: 50%;
  background-color: var(--darker-blue-43);
  border: 3px solid var(--darker-blue-43);
  color: #f5f8fa;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .flagsBox-container {
    padding: 20px;
    font-size: 14px;
  }
  .flagsBox-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .flagsBox-not-found {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 640px) {
  .flagsBox {
    display: none;
  }
}
