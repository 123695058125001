.vcrStatus__container {
  max-width: 180px;
  min-width: 160px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  font-family: 'ArticulatCF-Bold';
}

.vcrStatus__container img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .vcrStatus__container {
    max-width: 130px;
    min-width: 118px;
    height: 40px;
    font-size: 14px;
  }
  .vcrStatus__container img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
