#canvasOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* vehicle image view animation */
.overlayContainer .vehicleImageStyle.img0 {
  animation-duration: 1s;
  animation-name: fadeIn0;
}

.overlayContainer .vehicleImageStyle.img1 {
  animation-duration: 1s;
  animation-name: fadeIn1;
}

.overlayContainer .vehicleImageStyle.img2 {
  animation-duration: 1s;
  animation-name: fadeIn2;
}

.overlayContainer .vehicleImageStyle.img3 {
  animation-duration: 1s;
  animation-name: fadeIn3;
}

@keyframes fadeIn0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overlayContainer .vehicleImageStyle.img0 + #canvasOverlay {
  animation-duration: 2s;
  animation-name: fadeInRedDot0;
}
.overlayContainer .vehicleImageStyle.img1 + #canvasOverlay {
  animation-duration: 2s;
  animation-name: fadeInRedDot1;
}
.overlayContainer .vehicleImageStyle.img2 + #canvasOverlay {
  animation-duration: 2s;
  animation-name: fadeInRedDot2;
}
.overlayContainer .vehicleImageStyle.img3 + #canvasOverlay {
  animation-duration: 2s;
  animation-name: fadeInRedDot3;
}

@keyframes fadeInRedDot0 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
}
@keyframes fadeInRedDot1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
}
@keyframes fadeInRedDot2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
}
@keyframes fadeInRedDot3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
}
