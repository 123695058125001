.buttonContainer {
  align-items: center;
  justify-content: center;
  width: 220px;
  padding: 6px;
  font-size: 17px;
  font-family: 'ArticulatCF-Bold';
  border-radius: var(--element);
  background: var(--darker-blue-20);
}

.speedButton {
  width: 120px;
  height: 46px;
  border: none;
  color: #fff;
  border-radius: var(--element);
  background-color: var(--darker-blue-20);
}

.speedButton.active {
  background-color: var(--darker-blue-43);
}

.container img {
  width: 25px;
  height: 25px;
}

.container .iconText {
  color: white;
  font-size: 17px;
  margin-bottom: 14px;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .speedButton {
    width: 100px;
    height: 40px;
  }
}
