.formItem {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputItem {
  margin: 10px 0;
}

.errorItem {
  box-shadow: 0px 0px 0px 1px var(--base-red);
  width: stretch;
  height: fit-content;
}

.formItem-label {
  font-size: 18px;
  font-family: 'ArticulatCF-Medium';
  color: var(--darker-neutral-25);
}

.formItem-error {
  color: var(--base-red);
  font-size: 14px;
  position: absolute;
  font-family: 'ArticulatCF-Bold';
  bottom: 0;
}

.searchAudit-date-input
  .datePicker.white
  .react-datepicker__input-container
  input {
  width: 100%;
}

.searchAudit-date-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.datePicker.white .react-datepicker__input-container input {
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid #e5ecf2;
  padding: 0 10px;
}

.formItem input::placeholder {
  color: var(--base-neutral);
}

.formItem input[type='text'],
.formItem input[type='tel'] {
  outline: none;
  height: 40px;
  border: 1px solid #e5ecf2;
  padding: 0 10px;
  width: stretch;
}
