.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  min-width: 210px;
}

.inputForm__title {
  font-size: 14px;
  font-family: 'ArticulatCF-DemiBold';
  margin-bottom: 4px;
}

.inputForm__input {
  height: 40px;
  border-radius: var(--border-radius);
  background-color: #f8fafd;
  border: 1px solid #e1e7ef;
  align-items: center;
  padding: 10px;
  font-family: 'ArticulatCF-Bold';
}
