.userTableRow {
  position: relative;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid var(--lighter-neutral-92);
  background: white;
  margin: 4px 0;
  transition: height 0.2s linear;
  font-family: 'ArticulatCF-DemiBold';
}

.userTableRow.hover {
  cursor: pointer;
}

.userTableRow.hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.51s;
  -o-transition: all 0.51s;
  transition: all 0.51s;
}

.userTableRow.hover:hover::before {
  -webkit-box-shadow: 0px 3px 6px #0000001f;
  box-shadow: 0px 3px 6px #0000001f;
  -webkit-transform: scale(1.01, 1.1);
  -ms-transform: scale(1.01, 1.1);
  transform: scale(1.01, 1.1);
  border: 1px solid var(--darker-blue-43);
  border-radius: var(--element);
}

.userTableRow.hover:hover {
  border-bottom: 1px solid var(--lighter-neutral-99);
}

.expanded {
  justify-content: start;
  height: 250px;
}

.expanded:hover {
  background-color: #f7fafe;
}

.expanded .expandBtn {
  background-color: var(--lighter-neutral-99);
}

.confirmBox {
  height: 250px;
  background: var(--lighter-neutral-92);
  justify-content: center;
  align-items: center;
}

.userData {
  position: relative;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  width: 100%;
}

.userPage .tableColumn10 {
  word-wrap: break-word;
  white-space: pre-line;
}

.userPage .tableHeader {
  width: 100%;
  padding: 0;
  letter-spacing: 0;
  margin: 0;
  white-space: nowrap;
}

.userPage .tableContainer {
  height: 100%;
}

.expandBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  border: none;
  color: var(--base-blue);
  font-size: 18px;
  background: transparent;
  border-radius: var(--element);
}

.expandBtn img {
  height: 100%;
}

.arrow {
  align-items: center;
  padding: 0 5px;
}

.arrow p {
  margin: 0;
}

.userPage .tableColumnArrow {
  margin-left: 0px;
}

.down,
.up {
  font-family: 'Fontawesome webfont';
  color: var(--darker-blue-43);
}

.resetForm {
  justify-content: space-between;
  padding-left: 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  background: var(--lighter-neutral-92);
  animation-duration: 0.5s;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.resetPasswordTitle {
  color: var(--darker-blue-43);
  font-size: 18px;
  margin: 20px 0 10px 10px;
  font-family: 'ArticulatCF-Medium';
}

.passwordResetControls {
  justify-content: space-between;
}

.inputContainer {
  flex: 1;
  max-width: 700px;
}

.inputForm {
  flex: 1;
  justify-content: space-between;
}

.inputForm input {
  width: 30%;
  flex: 1;
  height: 60px;
  border: none;
  outline: none;
  margin: 10px;
  padding: 3%;
  font-size: 18px;
}

.inputForm input + input {
  margin-left: 5%;
}

.inputForm input::-webkit-input-placeholder {
  color: #bdcfe8;
  font-size: 14px;
}

.confirmBtnContainer {
  flex: 1;
  max-width: 400px;
  justify-content: space-between;
}

.passwordResetControls .confirmBtnContainer {
  margin-left: 10%;
  margin-right: 4%;
}

.cancelBtn,
.confirmBtn {
  flex: 1;
  height: 57px;
  margin: 10px;
  font-size: 17px;
  border: none;
  border-radius: var(--element);
  font-family: 'ArticulatCF-Bold';
}

.cancelBtn {
  color: #bdcfe8;
  background: var(--lighter-neutral-85);
  color: var(--darker-neutral-45);
  font-size: 17px;
}

.cancelBtn:hover {
  background-color: var(--base-red);
  color: white;
}

.confirmBtn {
  color: #ffffff;
  margin-left: 20px;
  background: var(--base-blue);
}

.confirmBtn:hover {
  background: var(--lighter-blue-82);
}

.confirmBox button {
  width: 164px;
}

.validationMsg {
  justify-content: space-between;
  min-height: 30px;
  color: #f91f70;
  margin-left: 20px;
}

.validationMsg > div {
  flex: 1;
}

.resetForm-message {
  flex: 1;
  margin-left: 20px;
  font-size: 15px;
  font-family: 'ArticulatCF-Medium';
}

.confirmAlert {
  font-size: 18px;
  color: var(--darker-blue-43);
  margin-bottom: 20px;
}

.doubleCheck {
  justify-content: center;
  align-items: center;
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(50%, 30%);
}

.manageUsers-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.manageUsers-rightHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 40%;
}

.manageUsers-rightHeader .searchForm {
  width: 210px;
}

.manageUsers-rightHeader .searchForm input {
  min-width: 50px;
}

.create-user-button {
  margin-left: 10px;
  background: none;
}

.create-user-button:hover {
  background: none;
}

.create-user-button img {
  width: 40px;
  height: 40px;
}

.manageUsers-select {
  width: 150px;
  font-size: 17px;
  z-index: 999;
}

.manageUsers-formItem {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5ecf2;
}

.manageUsers-formItem .Select-control {
  height: 48px;
}

.manageUsers-formItem .Select-value::before {
  min-width: 20px;
  min-height: 20px;
  left: 3%;
  pointer-events: none;
  content: '';
  background: url('../../images/icons/yard_calendar.svg') CENTER CENTER
    NO-REPEAT;
  position: absolute;
}

.manageUsers-formItem .Select-value-label {
  margin-left: 20px;
}

.manageUsers-formItem
  .Select.has-value.is-clearable.Select--single
  > .Select-control
  .Select-value {
  align-items: center;
  display: flex;
}

.manageUsers-formItem .Select-placeholder {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: 'ArticulatCF-Medium';
  color: var(--base-neutral);
}

.manageUsers-formItem-label {
  color: var(--darker-neutral-25);
}

.manageUsers__tabs {
  min-width: 320px;
  font-family: 'ArticulatCF-Medium';
  color: var(--darker-neutral-25);
}

.manageUsers__tabs .tabs-header {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .userTableRow {
    height: 48px;
    font-size: 14px;
  }
  .expandBtn {
    height: 38px;
    width: 68px;
    padding: 0;
    font-size: 14px;
  }
  .expanded {
    height: 180px;
  }
  .resetPasswordTitle {
    font-size: 14px;
    margin: 10px 0 6px 6px;
  }
  .resetForm {
    margin-top: 40px;
    margin-left: 0;
    margin-right: 14px;
    padding: 10px 10px 0 10px;
  }
  .inputForm input {
    height: 40px;
    margin: 6px;
    padding: 4px 4px 4px 18px;
    font-size: 14px;
  }
  .passwordResetControls .confirmBtnContainer {
    margin: 0 0px 0 20px;
  }
  .cancelBtn,
  .confirmBtn {
    font-size: 14px;
    margin: 0px;
  }
  .resetForm-message {
    font-size: 14px;
    margin-left: 10px;
    white-space: nowrap;
  }
  .validationMsg {
    min-height: 24px;
  }
  .confirmBox {
    height: 180px;
  }
  .confirmAlert {
    font-size: 16px;
    color: var(--darker-blue-43);
    margin-bottom: 10px;
  }
  .confirmBox button {
    width: 120px;
  }

  .userTableRow.hover:hover::before {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border: none;
    border-bottom: 1px solid var(--lighter-neutral-92);
    border-radius: var(--element);
  }

  .manageUsers__tabs {
    min-width: 200px;
  }
}

@media screen and (max-width: 1440px), screen and (max-height: 900px) {
  .create-user-button {
    margin-left: 8px;
  }

  .create-user-button img {
    width: 30px;
    height: 30px;
  }

  .manageUsers__tabs {
    min-width: 220px;
  }
}

.manage__users__username {
  font-size: 1.1rem;
  text-align: center;
}

.input__password {
  margin: 10px auto;
  display: block;
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  border: 1px solid var(--base-neutral);
}
