:root {
  --wrapper: 4px;
  --element: 2px;
  --one-fr: 1fr;

  --tooltip-border: 1px solid var(--darker-neutral-45);
  --tooltip-dark-theme-bg: var(--darker-blue-17);
  --tooltip-dark-theme-text: var(--lighter-neutral-99);
  --tooltip-light-theme-bg: var(--lighter-neutral-99);
  --tooltip-light-theme-text: var(--darker-blue-17);
  --tooltip-action-text: var(--lighter-neutral-99);

  --box-shadow: 0px 4px 20px var(--base-black-transparent);
  --border-radius: 4px;

  --font-bold: 'ArticulatCF-Bold';
  --font-demibold: 'ArticulatCF-DemiBold';
  --font-regular: 'ArticulatCF-Medium';
  --font-light: 'ArticulatCF-Light';

  /* devices panel */
  --moving-text-background: var(--darker-green-21);
  --idle-text-background: var(--darker-yellow-23);
  --missing-text-background: var(--darker-red-25);
  --comms-text-background: var(--darker-blue-29);
  --boxmoves-text-background: var(--darker-purple-29);
}
