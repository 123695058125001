.workerCharts {
  display: flex;
  flex-direction: column;
  width: 48.6%;
  margin-right: 10px;
}

.workerCharts-header {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.workerCharts-header-title {
  display: flex;
  height: 40px;
}

.workerCharts-header-title img {
  width: 20px;
}

.workerCharts-header-title h1 {
  font-size: 16px;
  margin-left: 10px;
}

.workerCharts-header span {
  font-size: 12px;
  color: var(--darker-neutral-45);
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 1px 5px var(--lighter-neutral-92);
  height: 30px;
  line-height: 30px;
  width: 100px;
  text-align: center;
}

.workerCharts-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.workerCharts-chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 32%;
  padding-left: 15px;
  padding-right: 5px;
  background-color: white;
  border: 1px solid #e5ecf2;
}

.workerCharts-chart-title {
  display: flex;
  height: 30px;
}

.workerCharts-chart-title img {
  width: 20px;
}

.workerCharts-chart-title h1 {
  font-size: 12px;
  margin-left: 10px;
}
