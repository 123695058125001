.rc-tooltip {
  opacity: 1;
  z-index: 9;
}

.rc-tooltip-inner {
  background-color: var(--darker-neutral-45);
  border-radius: var(--border-radius);
}

.rc-tooltip .rc-tooltip-arrow {
  border-top-color: var(--darker-neutral-45);
  height: 0;
}
