.individualView {
  flex: 1;
}

.view__container {
  justify-content: center;
  flex: 1;
  background-color: #f8fafd;
  border-radius: var(--border-radius);
  padding: 20px;
}

.galleryView {
  justify-content: space-between;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 4px;
}
.row__data.battery {
  position: relative;
  padding-left: 10px;
}

.row__data.battery img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 60px;
}

.row__data.lastcomms div {
  display: flex;
  align-items: center;
}

.row__data.lastcomms div p {
  margin: 0 0 0 10px;
  background: var(--lighter-neutral-92);
  padding: 6px 10px;
  border-radius: 20px;
}

.edit-vehicle-button {
  padding: 8px 40px;
  box-shadow: 0 2px 4px rgba(29, 121, 255, 0.5);
  font-size: 17px;
  font-weight: bold;
  font-family: 'ArticulatCF-Medium';
  margin-bottom: 8px;
}

.vehicle__tabs {
  display: flex;
  flex: 1;
}

.vcrList-container {
  width: 100%;
}

.vcrList-header {
  display: grid;
  grid-template-columns: 0.7fr repeat(3, 1fr) 0.5fr;
  column-gap: 10px;
  color: var(--base-neutral);
  padding: 20px 0 20px 20px;
}

.vcrList-body {
  height: 55vh;
  overflow-y: scroll;
}

.vcrList-row {
  display: grid;
  grid-template-columns: 0.7fr repeat(3, 1fr) 0.5fr;
  column-gap: 10px;
  padding: 5px 0 5px 20px;
  font-size: 0.9rem;
}

.vcrList-row:hover {
  cursor: pointer;
  background-color: var(--lighter-neutral-85);
}

.vcrList-row-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
}

.vcrList-row-item.issue {
  color: var(--darker-blue-43);
  overflow: hidden;
  white-space: nowrap;
}

.vcrList-row-item.issue span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.vcrList-row-item.icon {
  width: 24px;
  height: 24px;
  display: grid;
  margin: 0 auto;
}

.vcrList-row-item:not(.issue) span {
  color: var(--base-neutral);
}

@media screen and (max-width: 1240px), screen and (max-height: 900px) {
  .view__container {
    padding: 10px;
    height: 80%;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .view__container {
    flex-direction: column !important;
    height: 100%;
  }
}
