.customSlider {
  justify-content: center;
}

/* slider */
.card .rc-slider {
  position: relative;
  height: 8px;
  padding: 0;
  margin-top: 10px;
  width: 100%;
  border: none;
  touch-action: none;
  box-sizing: border-box;
}

.card .rc-slider-mark {
  top: -60px;
}

.card .rc-slider-mark-text {
  width: 12% !important;
  margin-left: -6% !important;
  padding: 8px;
  border-radius: 50px;
  background-color: var(--darker-blue-17);
  color: #fff;
  white-space: nowrap;
  min-width: 80px;
}

.card .rc-slider-mark-text-active {
  background-color: var(--darker-blue-43);
  color: #fff;
}

.card .rc-slider-rail {
  height: 8px;
  background: var(--darker-blue-20);
  border-radius: none;
}

.card .rc-slider-step {
  height: 8px;
}

.card .rc-slider-handle {
  width: 35px;
  height: 35px;
  margin-left: -17.49px;
  margin-top: -14px;
  border: none;
  box-shadow: 0 10px 8px rgba(21, 46, 93, 0.35);
}

.card .rc-slider-track {
  height: 8px;
  background-color: var(--darker-blue-43);
}

.card .rc-slider-dot {
  opacity: 0;
  bottom: 0px;
}

.card .rc-slider-dot-active {
  border-color: var(--darker-blue-43);
}

.playbackControls__content .rc-slider-handle p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 34px;
  margin-top: -50px;
  margin-left: -24px;
  color: white;
  font-size: 13px;
  font-family: 'ArticulatCF-Bold';
  background: var(--darker-blue-43);
  border-radius: var(--element);
  box-shadow: 0 5px 8px rgba(12, 24, 65, 0.5);
}

.card.disabled .playbackControls__content .rc-slider-handle p {
  background: #a4b3c8;
  box-shadow: none;
}

.card.disabled .rc-slider-rail {
  background: #6a7c95;
}

.card.disabled .rc-slider-track {
  background: #a4b3c8;
}
