.pagination {
  text-align: center;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.pagination div {
  width: 40px;
  font-family: 'ArticulatCF-Bold';
}

.pagination button {
  margin: 10px;
  width: 30px;
  height: 30px;
  border: none;
  color: white;
  border-radius: 50%;
  background-color: var(--base-blue);
  font-family: 'Fontawesome webfont', sans-serif;
}

.pagination button:disabled {
  background-color: var(--base-neutral);
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pagination {
    bottom: 4px;
  }
  .pagination button {
    margin: 6px;
    width: 28px;
    height: 28px;
    font-size: 16px;
  }
}
