.searchVCR-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.searchVCR-content {
  position: relative;
}

.searchVCR-alert-message {
  font-size: 14px;
  margin-top: 8px;
  color: red;
}

.searchVCR-title {
  margin-bottom: 20px;
}

.searchVCR-date-filter {
  height: 180px;

  margin-bottom: 32px;
  padding: 21px 43px;

  background: #ffffff;
  box-shadow: 0px 2px 2px #5c75a214;
  border-radius: var(--border-radius);
}

.searchVCR-date-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 10px;
}

.searchVCR-date-input
  .datePicker.white
  .react-datepicker__input-container
  input {
  width: 100%;
}

.searchReport-role-filter {
  height: 180px;

  margin-bottom: 32px;
  padding: 21px 43px;

  background: #ffffff;
  box-shadow: 0px 2px 2px #5c75a214;
  border-radius: var(--border-radius);

  @media (max-width: 1023px) {
    padding: 20px;
    height: auto;
  }
}

.searchReport-role-filter input {
  background: var(--lighter-neutral-96);
  width: 95%;
  height: 50px;
}

.btn-holder {
  justify-content: flex-end;
  display: flex;
}

.searchReport-search-btn {
  padding: 20px;
  width: 157px;
  height: 65px;
  background: var(--base-blue);
  box-shadow: 0px 2px 4px var(--base-blue);
  border-radius: var(--border-radius);
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font: Medium 17px/21px Articulat CF;
  outline: none;
  border: 1px solid #e5ecf2;
  border-radius: var(--border-radius);
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 95%;
  margin-right: 20px;
  background-color: #fff;
  font: Medium 17px/21px Articulat CF;
  border-radius: var(--border-radius);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
