.splashContainer {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.splashMessageContainer {
  font-size: 32pt;
  color: var(--darker-blue-43);
  background-color: #eaf2fc;
  border-radius: 17px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0px 50px 0px;
  text-align: center;
}

.splashImages {
  max-width: 90%;
  height: auto;
}
