.loading {
  width: 50px;
  height: 50px;
  margin: auto;
}

.with_opacity {
  opacity: 0.75;
}

.loading .st0,
.loading .st1,
.loading .st2,
.loading .st3,
.loading .st4,
.loading .st5 {
  fill: var(--darker-blue-43);
  stroke: #1b4eff;
  stroke-width: 4px;
  border: 2px solid #1b4eff;
  stroke-dasharray: 420;
}

.loading .st0 {
  animation: 2s text-outline0;
}
.loading .st1 {
  animation: 2s text-outline1;
}
.loading .st2 {
  animation: 2s text-outline2;
}
.loading .st3 {
  animation: 2s text-outline3;
}
.loading .st4 {
  animation: 2s text-outline4;
}
.loading .st5 {
  animation: 2s text-outline5;
}

.loading.infinite .st0 {
  animation: 2s text-outline0 infinite;
}
.loading.infinite .st1 {
  animation: 2s text-outline1 infinite;
}
.loading.infinite .st2 {
  animation: 2s text-outline2 infinite;
}
.loading.infinite .st3 {
  animation: 2s text-outline3 infinite;
}
.loading.infinite .st4 {
  animation: 2s text-outline4 infinite;
}
.loading.infinite .st5 {
  animation: 2s text-outline5 infinite;
}

.loading-text {
  font-size: 100px;
}

@keyframes text-outline0 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  40% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
@keyframes text-outline1 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  60% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
@keyframes text-outline2 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  20% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
@keyframes text-outline3 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  80% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
@keyframes text-outline4 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  50% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
@keyframes text-outline5 {
  0% {
    fill: #fff;
    stroke-dashoffset: 420;
  }
  30% {
    fill: #fff;
  }
  100% {
    fill: var(--darker-blue-43);
    stroke-dashoffset: 0;
  }
}
