.panel {
  flex: 1;
  padding: 20px;
  background: white;
  border: 1px solid #d8e2eb;
  border-radius: var(--border-radius);
}

.panel hr {
  border: none;
  height: 1px;
  margin-bottom: 20px;
  background: #e5ecf2;
}

.panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
}

.panel-icon img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.panel-title {
  font-size: 30px;
  font-family: 'ArticulatCF-Medium';
}

.panel-content {
  padding: 0 20px;
}

.panel-content .tableHeader {
  width: 100%;
  margin: 0 0 14px 0;
  padding: 0;
  font-family: 'ArticulatCF-DemiBold';
}

.panel-content .tableContainer {
  padding: 0;
  min-height: 40vh;
}

.panel-content .tableRow {
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: none;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .panel {
    padding: 14px;
  }
  .panel-icon img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }
  .panel-title {
    font-size: 24px;
  }
  .panel-header {
    margin-bottom: 10px;
  }
  .panel hr {
    margin-bottom: 14px;
  }
  .panel-content {
    padding: 0 14px;
  }
  .panel-content .tableHeader {
    margin: 0 0 10px 0;
  }
  .panel-content .tableRow:before {
    left: 0;
  }
}
