.card {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  height: 130px;
  /* overflow: hidden; */
  border-radius: var(--wrapper);
  box-shadow: 0 0px 20px rgba(34, 67, 116, 0.5);
  background: var(--darker-blue-17);
  transition: all 0.4s ease-in-out;
}

.card.live {
  height: 0px;
  opacity: 1;
  transition: all 0.4s ease-in;
}

.card.live .playbackFooter {
  opacity: 0;
  animation-name: fade;
  animation-duration: 0.6s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.playbackFooter {
  align-items: center;
  margin: 16px 16px auto;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.playbackTabWrapper {
  position: absolute;
  bottom: 16px;
  left: 10px;
  right: 10px;
  transition: all 0.3s ease-in;
  width: fit-content;
}

.playbackTab {
  background: #232c51;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.playbackTab .flex-row {
  align-items: center;
}

.timePlayBtn__container {
  width: 160px;
}

.currentTimeLabel {
  width: 160px;
  margin-bottom: 14px;
}

.currentTimeLabel img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.currentTimeLabel p {
  margin: 0;
  color: #fff;
  font-size: 17px;
  white-space: nowrap;
}

.playbackControls__content {
  width: 100%;
  flex: 1 1 auto;
  margin-left: 20px;
  padding: 0px 20px 20px;
  background: var(--darker-blue-17);
}

.card.disabled .playbackControls__content {
  background: var(--darker-neutral-45);
}

.card__play,
.card__pause {
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 56px;
  font-size: 16px;
  border: none;
  color: #fff;
  border-radius: var(--element);
  font-family: 'ArticulatCF-Bold';
}

.card__play,
.card__play:focus {
  background: var(--darker-blue-43);
}

.card__play:disabled {
  background: #a4b3c8;
}

.play_icon,
.pause_icon {
  font-family: 'Fontawesome webfont';
}

.card__pause,
.card__pause:focus {
  background: var(--base-red);
}

.card.disabled {
  background: var(--darker-neutral-45);
}

.card.disabled .buttonContainer {
  background: #a4b3c8;
}

.card.disabled .speedButton.active:disabled {
  background: var(--darker-neutral-45);
}

.card.disabled .speedButton {
  background: #a4b3c8;
}

.toggleContainer {
  z-index: 10;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .card {
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: 110px;
  }

  .playbackFooter {
    margin: 10px 10px auto;
  }
  .playbackTab {
    padding: 16px;
  }
  .playbackTabWrapper {
    bottom: 10px;
  }
  .timePlayBtn__container {
    width: 160px;
  }
  .currentTimeLabel p {
    font-size: 15px;
  }
  .currentTimeLabel img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .currentTimeLabel {
    margin-bottom: 10px;
  }
  .card__play,
  .card__pause {
    width: 90px;
    height: 46px;
    font-size: 14px;
  }
  .container .iconText {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .buttonContainer {
    width: 174px;
    padding: 4px;
    font-size: 15px;
  }
  .container img {
    width: 23px;
    height: 23px;
  }
  .playbackControls__content {
    margin: 0 10px;
    padding: 6px 20px 20px;
  }
  .playbackControls__content .rc-slider-handle p {
    width: 60px;
    height: 30px;
    margin-top: -44px;
    margin-left: -14px;
    font-size: 12px;
  }
}
