.commentBoxContainer {
  padding: 10px;
  width: 100%;
  height: 100%;
  border: none;
  background: #fff;
  border-radius: var(--wrapper);
}

.commentBoxHeader {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--lighter-neutral-92);
  font-size: 34px;
  font-family: 'ArticulatCF-Medium';
}

.commentBoxHeader img {
  width: 37px;
  margin-right: 14px;
}

.commentBox {
  height: 55vh;
  overflow-y: scroll;
  padding-right: 10px;
}

.elmentComment {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  background: #f0f4f9;
  border-radius: var(--element);
}

.elementLeft,
.elementDetail {
  font-size: 15px;
}

.elementDetail {
  margin-bottom: 20px;
  justify-content: space-between;
}

.userName {
  padding: 2px 14px;
  margin: 10px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--base-blue);
  border-radius: 12px;
  border: 1px solid var(--base-blue);
}

.elementName {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 12px;
  padding: 1px 10px;
  margin: 10px 0;
}

.elementName img {
  width: 16px;
  margin-right: 6px;
}

.elementDate {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 15px;
  color: var(--base-neutral);
  font-family: 'ArticulatCF-ExtraBold';
}

.commentClock {
  width: 18px;
  margin-right: 6px;
}

.commnetInput {
  position: relative;
}

.commentInputBox {
  resize: none;
  outline: none;
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  font-size: 17px;
  border: 1px solid #d1ddee;
  border-radius: var(--element);
}

.commentSendIconWrapper {
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;

  position: absolute;
  bottom: 20px;
  right: 10px;

  outline: none;
  background: var(--darker-blue-43);
  border-radius: var(--element);
  color: white;
  font-size: 18px;
  font-family: 'ArticulatCF-Bold';
}

.commentSendIcon {
  width: 20px;
  margin-right: 10px;
}
@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .commentBoxContainer {
    padding: 10px;
  }
  .commentBox {
    padding-right: 6px;
    margin: 6px -6px 6px 6px;
  }
  .commentBoxHeader {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .commentBoxHeader img {
    width: 27px;
    margin-right: 10px;
  }
  .elmentComment {
    padding: 6px 10px;
    font-size: 14px;
  }
  .userName,
  .elementName {
    padding: 1px 8px;
    margin: 4px;
  }
  .elementLeft,
  .elementDetail {
    font-size: 13px;
  }
  .elementDetail {
    margin-bottom: 10px;
  }
  .elementName img {
    width: 12px;
    margin-right: 4px;
  }
  .elementDate {
    margin-right: 10px;
    font-size: 14px;
  }
  .commentClock {
    width: 14px;
    margin-right: 4px;
  }
  .commentSendIconWrapper {
    padding: 6px 14px;
    bottom: 14px;
    right: 6px;
    font-size: 16px;
  }
  .commentSendIcon {
    width: 14px;
  }
}
