.menu {
  max-width: 100%;
  height: 100%;
}

.menu-container {
  position: absolute;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);
}

.menu-container > ul {
  margin: 10px 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.menu-container > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  padding: 10px;
}

.menu-container > ul > li:hover {
  color: #418eff;
  background-color: var(--lighter-blue-96);
}
