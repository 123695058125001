.cardGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .cardGroup {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .cardGroup {
    flex-wrap: wrap;
  }
}
