/* TRACKING STYLES */
.sliderContainer {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.trackingPageWrapper {
  position: relative;
  width: 100%;
}

.toggleContainer {
  position: absolute;
  justify-content: center;
  text-align: center;
  top: 15px;
  right: 50%;
  border: none;
  border-radius: var(--wrapper);
  box-shadow: var(--box-shadow);
}

.trackingPageWrapper .boxes-container {
  position: absolute;
  width: 380px;
  top: 16px;
  right: 16px;
  pointer-events: none;
}

.trackingPageWrapper .flagsBox-container {
  z-index: 10;
}

.trackingPageWrapper .flagsBox-button {
  z-index: 10;
}

.trackingPageWrapper .badge-icon {
  z-index: 11;
}

/* beacon animation effect on selected status icon */
.beacon,
.dot_beacon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  border: 3px solid;
  border-radius: 250px;
  height: 50px;
  width: 50px;
  animation: beacon 1s linear infinite;
}

@keyframes beacon {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes markerDotFrame {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  51% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .toggleContainer {
    top: 10px;
    right: 50%;
  }

  .trackingPageWrapper .boxes-container {
    width: 340px;
    height: 75%;
  }
}

@media (max-width: 600px) {
  .toggleContainer {
    display: none;
  }
}
