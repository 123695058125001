.workerProfile .pageHeader {
  justify-content: space-between;
  align-items: flex-start;
}

.workerProfile .pageHeader .title {
  margin: 8px;
  font-family: 'ArticulatCF-Medium';
  font-size: 34px;
  color: var(--darker-neutral-25);
}

.workerProfile-subtitle {
  font-size: 14px;
  margin-bottom: 10px;
}

.workerProfile-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
}

.workerProfile-content-columns {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .workerProfile .pageHeader {
    margin: 20px 10px 10px;
    align-items: center;
  }

  .workerProfile .pageHeader .title {
    font-size: 20px;
  }
}
