@font-face {
  font-family: 'ArticulatCF-Thin';
  font-style: normal;
  font-weight: 100;
  src: local('ArticulatCF Thin'), local('ArticulatCF-Thin'),
    url('./fonts/ArticulatCF-Thin.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-ThinOblique';
  font-style: oblique;
  font-weight: 100;
  src: local('ArticulatCF ThinOblique'), local('ArticulatCF-ThinOblique'),
    url('./fonts/ArticulatCF-ThinOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-ExtraLight';
  font-style: normal;
  font-weight: 200;
  src: local('ArticulatCF ExtraLight'), local('ArticulatCF-ExtraLight'),
    url('./fonts/ArticulatCF-ExtraLight.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-ExtraLightOblique';
  font-style: oblique;
  font-weight: 200;
  src: local('ArticulatCF ExtraLightOblique'),
    local('ArticulatCF-ExtraLightOblique'),
    url('./fonts/ArticulatCF-ExtraLightOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-Light';
  font-style: normal;
  font-weight: 300;
  src: local('ArticulatCF Light'), local('ArticulatCF-Light'),
    url('./fonts/ArticulatCF-Light.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-LightOblique';
  font-style: oblique;
  font-weight: 300;
  src: local('ArticulatCF LightOblique'), local('ArticulatCF-LightOblique'),
    url('./fonts/ArticulatCF-LightOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* standard font weight */
@font-face {
  font-family: 'ArticulatCF-Text';
  font-style: normal;
  font-weight: 400;
  src: local('ArticulatCF Text'), local('ArticulatCF-Text'),
    url('./fonts/ArticulatCF-Text.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-TextOblique';
  font-style: oblique;
  font-weight: 400;
  src: local('ArticulatCF TextOblique'), local('ArticulatCF-TextOblique'),
    url('./fonts/ArticulatCF-TextOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('ArticulatCF Medium'), local('ArticulatCF-Medium'),
    url('./fonts/ArticulatCF-Medium.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-MediumOblique';
  font-style: oblique;
  font-weight: 500;
  src: local('ArticulatCF MediumOblique'), local('ArticulatCF-MediumOblique'),
    url('./fonts/ArticulatCF-MediumOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-DemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('ArticulatCF DemiBold'), local('ArticulatCF-DemiBold'),
    url('./fonts/ArticulatCF-DemiBold.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-DemiBoldOblique';
  font-style: oblique;
  font-weight: 600;
  src: local('ArticulatCF DemiBoldOblique'),
    local('ArticulatCF-DemiBoldOblique'),
    url('./fonts/ArticulatCF-DemiBoldOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('ArticulatCF Bold'), local('ArticulatCF-Bold'),
    url('./fonts/ArticulatCF-Bold.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-BoldOblique';
  font-style: oblique;
  font-weight: 700;
  src: local('ArticulatCF BoldOblique'), local('ArticulatCF-BoldOblique'),
    url('./fonts/ArticulatCF-BoldOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-ExtraBold';
  font-style: normal;
  font-weight: 700;
  src: local('ArticulatCF ExtraBold'), local('ArticulatCF-ExtraBold'),
    url('./fonts/ArticulatCF-ExtraBold.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-ExtraBoldOblique';
  font-style: oblique;
  font-weight: 700;
  src: local('ArticulatCF ExtraBoldOblique'),
    local('ArticulatCF-ExtraBoldOblique'),
    url('./fonts/ArticulatCF-ExtraBoldOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArticulatCF-Heavy';
  font-style: normal;
  font-weight: 800;
  src: local('ArticulatCF Heavy'), local('ArticulatCF-Heavy'),
    url('./fonts/ArticulatCF-Heavy.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'ArticulatCF-HeavyOblique';
  font-style: oblique;
  font-weight: 800;
  src: local('ArticulatCF HeavyOblique'), local('ArticulatCF-HeavyOblique'),
    url('./fonts/ArticulatCF-HeavyOblique.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
