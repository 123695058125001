.safetyReports {
  position: relative;
}

.safetyReports .pageHeader .title {
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  font-family: 'ArticulatCF-Medium';
  font-size: 34px;
  color: var(--darker-neutral-25);
}

.safetyReports-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  @media (max-width: 1024px) {
    padding: 0;
  }
}

.safetyReports-content .tableBody {
  min-height: 200px;
}

.safetyReports-content .tableRow {
  min-height: 50px;
  cursor: default;
}
.safetyReports-content .tableRow::before {
  content: none;
}

.safetyReports-content .tableContainer {
  min-height: auto;
  max-height: unset;
}
.safetyReports-content .panel-content {
  min-height: 200px;
}
.safetyReports-content .tableColumn7:first-child {
  width: 8%;
}
.safetyReports-content .tableColumn7:last-child {
  width: unset;
}
.safetyReports-content .tableColumn9 {
  display: flex;
  align-items: center;
}
.safetyReports-content .tableColumn9 {
  overflow: auto;
}
.safetyReports-content .tableColumn9:first-child {
  width: 4%;
}
.safetyReports-content .tableColumn9:nth-child(5) {
  width: 16.5%;
}
.safetyReports-content .tableColumn9:nth-child(6) {
  width: 20%;
}
.safetyReports-content .tableColumn9:nth-child(7) {
  width: 16.5%;
}
.safetyReports-content .tableColumn9:nth-child(8) {
  width: 3%;
}
.safetyReports-content .tableColumn9:last-child {
  width: 8%;
  display: flex;
  align-items: center;
  overflow: visible;
  padding-left: 10px;
}

.safetyReports-content .panel {
  flex: 1;
  padding: 20px;
}

.safetyReports .search-report {
  padding: 20px;
}

.safetyReports .pageHeader .title span {
  width: 20%;
}
.safetyReports .pageHeader .title span:first-child {
  width: 25%;
}

.safetyReports .new-safety-report {
  padding: 20px 40px;
  box-shadow: 0 2px 4px rgba(29, 121, 255, 0.5);
  font-size: 17px;
  font-family: 'ArticulatCF-Medium';
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 30px;
  bottom: 20px;
}

.safetyReports .new-safety-report img {
  padding: 10px;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
}

.safetyReports .actionButton {
  border: none;
  color: var(--base-blue);
  font-size: 18px;
  height: 35px;
  padding: 0 5px;
  background: var(--lighter-neutral-96);
  border-radius: var(--element);
}

.safetyReports .actionButton:hover {
  background-color: var(--lighter-neutral-85);
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .safetyReports .pageHeader .title {
    margin: 4px;
  }
  .safetyReports .pageHeader .title span {
    font-size: 28px;
  }
  .safetyReports .search-report {
    padding: 14px;
  }
  .safetyReports .new-safety-report {
    padding: 14px 24px;
  }
  .safetyReports .new-safety-report img {
    padding: 6px;
    width: 30px;
    height: 30px;
  }
  .safetyReports .new-safety-report {
    right: 10px;
  }
  .safetyReports-content .tableRow {
    min-height: 40px;
  }
  .safetyReports .pageHeader .title .workerFilter__container span {
    font-size: 16px;
  }
  .safetyReports .pageHeader .title .dateRange__container span {
    font-size: 16px;
  }
  .safetyReports .pageHeader .title span:first-child {
    width: 20%;
  }
}
