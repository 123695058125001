.sendForm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: 1px solid var(--lighter-blue-82);
  border-radius: var(--border-radius);
  z-index: 0;
  background: white;
}

.sendForm input {
  border: 0;
  padding: 0 25px;
  outline: none;
  width: 100%;
}

.sendForm-button {
  padding: 4px;
}

.sendForm-button button {
  padding: 0px;
  outline: none;
  background-image: url('../../images/icons/send.svg');
  width: 30px;
  height: 30px;
  background-color: var(--lighter-neutral-92);
  border-radius: var(--border-radius);
  border: 0;
}

.sendForm-button button:disabled {
  opacity: 0.6;
}
