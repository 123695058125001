.issue__state {
  flex-wrap: wrap;
  max-height: 128px;
  overflow: auto;
  min-height: 64px;
}

.issue__state-item {
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 18px;
  font-family: 'ArticulatCF-Medium';
  color: #465365;
  padding: 14px 16px;
  margin-right: 14px;
  margin-bottom: 14px;
  height: 50px;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 10px #3380cd1a;
}

.issue__state-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.noIssueReport {
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  color: var(--darker-green-33);
  font-size: 18px;
  font-family: 'ArticulatCF-Bold';
  border-radius: var(--element);
  border: 3px solid var(--lighter-green-82);
}

.noIssueReport p {
  margin: 0;
  color: var(--darker-green-33);
  font-size: 28px;
  margin-right: 10px;
  font-family: 'Fontawesome webfont';
}
@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .noIssueReport {
    padding: 6px 10px;
    font-size: 14px;
    border: 2px solid;
  }
  .noIssueReport p {
    font-size: 24px;
    margin-right: 6px;
  }
  .issue__state-item {
    font-size: 14px;
    padding: 8px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .issue__state {
    max-height: 128px;
    max-height: 64px;
  }
}
