/* search form */
.searchForm input {
  border: none;
  outline: none;
  font-size: 18px;
  color: var(--darker-blue-43);
  min-width: 250px;
  background: #fff;
  margin-left: 30px;
}

.searchForm input::-webkit-input-placeholder {
  color: #bdcfe8;
}

.searchForm {
  width: 310px;
  height: 50px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #e1e7ef;
  border-radius: var(--element);
}

.searchIcon {
  font-family: 'Fontawesome webfont';
  margin-right: 25px;
  font-size: 18px;
  color: var(--darker-blue-43);
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .searchForm input {
    font-size: 16px;
    min-width: 174px;
    margin-left: 24px;
  }
  .searchForm {
    width: 210px;
    height: 40px;
    margin: 6px 0;
  }
  .searchIcon {
    font-size: 16px;
  }
}
