.react-toggle-track {
  background-color: var(--darker-neutral-45);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--darker-neutral-45);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--darker-blue-43);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--base-blue);
}

.react-toggle--checked .react-toggle-thumb,
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
  border-color: var(--lighter-neutral-99);
}
