.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container input {
  outline: none;
  font-family: 'ArticulatCF-DemiBold';
  padding: 10px 10px 10px 20px;
  border: 0px;
}

.datePicker.white .react-datepicker__input-container input {
  width: 300px;
  height: 50px;
  font-size: 16px;
  color: var(--base-neutral);
  border: 1px solid var(--lighter-neutral-85);
  border-radius: var(--border-radius);
}

.datePicker.black .react-datepicker__input-container input {
  width: 250px;
  height: 50px;
  font-size: 18px;
  color: white;
  background-color: var(--darker-blue-17);
  border: 1px solid var(--darker-blue-17);
  border-radius: var(--border-radius);
}

.react-datepicker__input-container::before {
  width: 5%;
  height: 100%;
  min-width: 16px;
  min-height: 16px;
  right: 1%;
  pointer-events: none;
}

.datePicker.white .react-datepicker__input-container::before {
  content: '';
  background: url('../../images/icons/yard_calendar.svg') CENTER CENTER
    NO-REPEAT;
  position: absolute;
  right: 14px;
}

.datePicker.black .react-datepicker__input-container::before {
  content: '';
  background: url('../../images/icons/calendar-white.svg') CENTER CENTER
    NO-REPEAT;
  position: absolute;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .datePicker.black .react-datepicker__input-container input {
    height: 40px;
  }
  .datePicker.white .react-datepicker__input-container input {
    width: 210px;
    height: 44px;
  }

  .react-datepicker__input-container input {
    width: 200px;
    height: 40px;
    font-size: 14px;
  }
}
