.summary__chart {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  position: relative;
  min-height: 220px;
}

.summary__chart .chart__label {
  margin: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  font-family: 'ArticulatCF-Medium';
  color: var(--darker-neutral-45);
}

.chartAxis__label {
  align-items: center;
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 0;
  justify-content: space-between;

  font-size: 14px;
  font-family: 'ArticulatCF-Bold';
  color: var(--darker-neutral-45);
}

.chartAxis__label .label__date {
  border: 2px solid var(--lighter-neutral-92);
  padding: 1px 10px;
  margin-left: 10px;
  border-radius: 17px;
  color: var(--base-blue);
  font-family: 'ArticulatCF-Bold';
}

.chartAxis__label .flex-row {
  align-items: center;
}

.summary__chart .recharts-legend-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 15px var(--lighter-neutral-85);
  border-radius: var(--border-radius);
  background-color: white;
  padding: 10px;
  font-size: 16px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-DemiBold';
}

.summary__chart .custom-tooltip {
  padding: 10px;
  width: 200px;
  height: 110px;
  border-radius: var(--border-radius);
  background-color: white;
  box-shadow: 0 6px 12px #30568914;
}

.summary__chart .custom-tooltip p {
  margin: 0;
}

.summary__chart .custom-tooltip hr {
  width: 100%;
  background: var(--lighter-neutral-92);
  height: 1px;
  border: none;
  margin-top: 0;
  margin-bottom: 16px;
}

.summary__chart .custom-tooltip .activeDate {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  font-family: 'ArticulatCF-Bold';
  color: var(--base-blue);
  padding-bottom: 6px;
  white-space: nowrap;
}

.summary__chart .custom-tooltip img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-left: 8px;
}

.summary__chart .custom-tooltip .row {
  display: flex;
  flex-direction: row;
}

.summary__chart .custom-tooltip .col {
  display: flex;
  flex-direction: column;
}

.summary__chart .custom-tooltip .extraMetrics {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  grid-template-rows: auto;
  font-family: 'ArticulatCF-Bold';
  font-size: 14px;
  background-color: var(--lighter-neutral-96);
  color: var(--darker-neutral-45);
  padding: 4px 8px 4px 8px;
}

.summary__chart .custom-tooltip .extraMetric {
  color: var(--base-blue);
}

.summary__chart .custom-tooltip .extraMetrics .hp {
  grid-column: 1 / 9;
  grid-row: 1;
}

.summary__chart .custom-tooltip .extraMetrics .hp-m {
  grid-column: 4 / 9;
  grid-row: 1;
}

.summary__chart .custom-tooltip .extraMetrics .at {
  grid-column: 1 / 9;
  grid-row: 2;
}

.summary__chart .custom-tooltip .extraMetrics .at-m {
  grid-column: 4 / 9;
  grid-row: 2;
}

.summary__chart .custom-tooltip .extraMetrics .ar {
  grid-column: 7 / 9;
  grid-row: 1;
}

.summary__chart .custom-tooltip .extraMetrics .ar-m {
  grid-column: 9 / 9;
  grid-row: 1;
}

.summary__chart .custom-tooltip .extraMetrics .lpmh {
  grid-column: 6 / 9;
  grid-row: 2;
}

.summary__chart .custom-tooltip .extraMetrics .lpmh-m {
  grid-column: 9 / 9;
  grid-row: 2;
}

.summary__chart .custom-tooltip .label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-family: 'ArticulatCF-DemiBold';
  margin: 0 8px;
}

.summary__chart .custom-tooltip .indicator {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

.recharts-rectangle.recharts-tooltip-cursor {
  fill: rgba(0, 0, 0, 0);
}

.recharts-layer.recharts-bar-rectangle .recharts-rectangle {
  opacity: 0.3;
}

.opaque-bar {
  opacity: 1 !important;
}

.recharts-wrapper {
  transition: all 0.4s ease-in-out;
}
.recharts-surface {
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  /* chart */
  .summary__chart .chart__label {
    font-size: 26px;
  }
  .chartAxis__label {
    left: 30px;
    right: 30px;
    font-size: 12px;
  }
  .chartAxis__label .label__date {
    padding: 1px 8px;
    margin-left: 6px;
  }
  .summary__chart .recharts-legend-wrapper {
    font-size: 14px;
    padding: 8px;
  }
}
