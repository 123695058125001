.tableHeader {
  width: 98%;
  margin: 10px;
  padding: 0 30px;
  font-size: 18px;
  letter-spacing: 1px;
  color: var(--base-neutral);
  font-family: 'ArticulatCF-Heavy';
}

.tableContainer {
  padding: 0 10px 10px;
  overflow: auto;
}

.tableRow {
  width: 98%;
  min-height: 60px;
  margin: 10px;
  padding: 7px 30px;
  position: relative;
  border-bottom: 1px solid var(--lighter-neutral-92);
  font-size: 18px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-DemiBold';
}

.tableRow::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.51s;
  -o-transition: all 0.51s;
  transition: all 0.51s;
}

a .tableRow:hover::before {
  -webkit-box-shadow: 0px 3px 6px #0000001f;
  box-shadow: 0px 3px 6px #0000001f;
  -webkit-transform: scale(1.01, 1.1);
  -ms-transform: scale(1.01, 1.1);
  transform: scale(1.01, 1.1);
  border: 1px solid var(--darker-blue-43);
  border-radius: var(--element);
}

a .tableRow:hover {
  border-bottom: 1px solid var(--lighter-neutral-99);
}

.preventClick {
  outline: none;
}

.tableColumnOrderable {
  cursor: pointer;
  user-select: none;
}

.tableColumnArrow {
  color: #3b415b;
  margin-left: 10px;
}

.tableColumn3 {
  width: 33%;
  display: flex;
  align-items: center;
}

.tableColumn4 {
  width: 25%;
  display: flex;
  align-items: center;
}

.tableColumn5 {
  width: 30%;
  display: flex;
  align-items: center;
}

.tableColumn6 {
  width: 16%;
  display: flex;
  align-items: center;
}

.tableColumn7 {
  width: 14%;
  color: #3b415b;
}

.tableHeader .tableColumn7 {
  color: var(--base-neutral);
}

.tableColumn9 {
  width: 11.1%;
}

.tableColumn10 {
  width: 10%;
}

.tableColumn11 {
  width: 9.09%;
}

.tableBody > div {
  outline: none;
}

.tableColumn {
  display: flex;
  padding-right: 5px;
}

.tableHeader__box {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.workerList .tableHeader__box {
  max-width: 6vw;
}

.tableHeader__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 0 2px 0 0;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .tableHeader {
    padding: 0;
    margin: 0;
    letter-spacing: 0;
    font-size: 14px;
    white-space: nowrap;
  }
  .tableRow {
    min-height: 44px;
    margin: 2px 0;
    padding: 0;
    font-size: 14px;
  }
  .tableContainer {
    padding: 0;
    margin: 0;
  }
  .tableBody {
    padding: 0;
  }
  .tableRow::before {
    left: 4px;
    right: 0px;
  }
}
