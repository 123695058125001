.input {
  margin: 0 10px 30px;
}

.input__label {
  letter-spacing: 3px;
  color: #bdcfe8;
  font-size: 12px;
  margin: 0 0 5px;
}

.input__text {
  border: none;
  border-bottom: 1px solid #ebf3ff;
  color: #3b415b;
  font-size: 17px;
  padding-bottom: 8px;
  width: 100%;
  outline: none;
}
