.parkedMarker {
  animation: parkedMarkerFrame 1s linear infinite;
}

@keyframes parkedMarkerFrame {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  51% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

@keyframes parkedFrame {
  0% {
    visibility: visible;
  }
  50% {
    visibility: visible;
  }
  51% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
