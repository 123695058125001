.flag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  min-height: fit-content;
  border-radius: var(--element);
}

/* light type */
.light .flag {
  background: var(--lighter-neutral-99);
  color: var(--darker-neutral-25);
  padding: 3% 6%;
}

.light .flag h3 {
  color: black;
}

.light .flag .flag-content {
  align-items: center;
}

.light .flag .flag-content-img {
  background-color: white;
  box-shadow: 0 2px 2px var(--lighter-blue-96);

  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light .flag .flag-time {
  background-color: var(--lighter-neutral-92);
}

.light .flag .flag-content-img img {
  width: 24px;
  height: 24px;
}

.light hr {
  display: none;
}

.flag .flag-content-img {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
}

.flag .flag-content-img img {
  width: 30px;
  height: 30px;
}

.flag-content {
  display: flex;
  flex: 1;
}

.flag h3 {
  margin: 0;
  font-family: 'ArticulatCF-Bold';
}

.flag h5 {
  margin: 0;
  font-size: 12px;
  color: var(--base-blue);
}

.flag-content p {
  margin: 6px 0;
  font-size: 12px;
}

.flag-content-text {
  flex: 1;
  margin-left: 10px;
}

.flag-content-text div {
  display: inline;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  color: var(--darker-neutral-45);
}

.flag .flag-time {
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 20px;
  background: var(--darker-blue-20);
}

.flag-play {
  display: inline;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  padding: 4%;
}

.flag-play.disabled {
  color: var(--darker-neutral-45);
  background-color: var(--lighter-neutral-92);
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .flag {
    margin-bottom: 10px;
  }
}
