.toggleContainer {
  background: var(--darker-blue-17);
}

.button.toggle {
  padding: 12px 14px;
  background-color: var(--darker-blue-17);
  margin: 6px;
  display: inline-block;
}

.toggle + .toggle {
  margin-left: 0;
}

.toggle:hover,
.toggle:focus,
.toggle.active {
  background: var(--darker-blue-43);
  transition: all 0.2s ease-in;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .toggle {
    padding: 10px 12px;
    margin: 4px;
  }
}
