.selectedDayCard {
  display: flex;
  width: 48.6%;
  height: 100%;
  margin-left: 10px;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e5ecf2;
  border-radius: var(--border-radius);
  padding: 8px;
}

.selectedDayCard-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  max-width: 500px;
}

.selectedDayCard-header-date {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100px;
}

.selectedDayCard-header-date img {
  width: 20px;
}

.selectedDayCard-header-date span {
  margin-left: 10px;
  max-width: 500px;
}

.selectedDayCard-header-tabs {
  display: flex;
  flex: 2;
  justify-content: center;
  width: 50%;
}

.selectedDayCard-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: safe center;
}
