.vehicles .statusSelector {
  min-width: 180px;
  max-width: 180px;
  width: 180px;
}

.vehicles .Select-control {
  padding: 4px;
  height: 30px;
  border-radius: var(--element);
  display: flex;
  flex-direction: row;
  position: relative;
}

.vehicles .Select-value {
  margin-left: 6px;
}

.vehicles .searchForm {
  width: 210px;
}

.vehicles .searchForm input {
  min-width: 50px;
}

.vehicles .Select-arrow-zone {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.vehicles .Select-input {
  width: 0;
  margin: 0;
  padding: 0;
}

.vehicles .tableColumn5 p {
  margin: 0;
}

.vehicleStatusSelectorArrow {
  width: 20px;
  height: 20px;
  padding: 5px;
  font-family: 'Fontawesome webfont';
}

.vehicles .Select-menu-outer {
  margin-left: -5px;
  padding-top: 20px;
  height: 174px;
  box-shadow: 0 3px 6px #0000001f;
  border-radius: var(--element);
}
.vehicles .Select.is-open .Select-menu-outer {
  animation-duration: 0.5s;
  animation-name: statusAnimation;
}

.reload-vehicle-button {
  padding: 8px 40px;
  box-shadow: 0 2px 4px rgba(29, 121, 255, 0.5);
  font-size: 17px;
  font-weight: bold;
  font-family: 'ArticulatCF-Medium';
  margin-bottom: 8px;
}

/* batter image style */
.vehicles .tableColumn7 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.vehicles .tableRow p {
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.vehicles .tableRow img {
  width: 54px;
  position: absolute;
  left: -6px;
}

@keyframes statusAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 174px;
  }
}

.vehicles .Select.is-open .Select-option {
  animation-duration: 0.5s;
  animation-name: optionFade;
}

@keyframes optionFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .vehicles .statusSelector {
    min-width: 140px;
    max-width: 140px;
    width: 140px;
  }
  .vehicles .Select-control {
    height: 40px;
  }
  .vehicles .Select--single > .Select-control .Select-value {
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 18px;
  }
  .vehicles .Select-arrow-zone {
    padding: 0;
    justify-content: center;
  }
  @keyframes statusAnimation {
    0% {
      height: 0;
    }
    100% {
      height: 144px;
    }
  }
  .vehicles .tableRow img {
    width: 50px;
  }
}
