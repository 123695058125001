.galleryContainer {
  display: flex;
  flex: 1;
}
.overlayWrapper,
.overlayContainer {
  width: 100%;
  height: 100%;
}

.overlayContainer {
  position: relative;
}

.vehicleImageStyle {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.viewMode_text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: 'ArticulatCF-ExtraBold';
  color: var(--base-neutral);
  background: var(--lighter-neutral-92);
  border-radius: 24px;
  border: none;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 40px;
}

.galleryButtonContainer {
  z-index: 10;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.galleryButtons {
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: var(--element);
  background: var(--lighter-neutral-92);
  font-size: 24px;
  color: var(--darker-blue-43);
  font-family: 'Fontawesome webfont', sans-serif;
}

.dotButtonContainer {
  display: flex;
  align-items: center;
}

.galleryDotButton {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: none;
  background-color: var(--lighter-neutral-85);
  margin: 0 10px;
  padding: 0;
}

.galleryDotButtonHighlighted {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #eaf2fc;
  background-color: var(--darker-blue-43);
  margin: 0 10px;
}

.galleryContainer.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-neutral);
  pointer-events: none;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .viewMode_text {
    font-size: 14px;
    width: 120px;
    min-height: 30px;
    margin-top: -40px;
  }
  .galleryButtonContainer {
    min-height: 34px;
  }
  .galleryButtons {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }
  .galleryDotButtonHighlighted {
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) {
  .overlayWrapper {
    min-height: 300px;
  }
}
