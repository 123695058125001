.vcrVehicleTabs {
  width: 100%;
  background: var(--lighter-neutral-99);
  box-shadow: var(--box-shadow);
  border-radius: var(--wrapper);
}

.vcrVehicleTabs__header {
  display: flex;
  justify-content: center;
}

.vcrVehicleTabs__tabs {
  width: 65%;
}

.vcrVehicleTabs__tabs .tab img[src*='vcr_icon'] {
  height: 1.5em;
}

.vcrVehiclesTabs__body {
  height: 100%;
}
