:root {
  /* orange palette */
  --lighter-orange-90: hsl(26, 98%, 90%);
  --base-orange: hsl(26, 98%, 59%);
  --darker-orange-27: hsl(26, 98%, 27%);

  /* yellow palette */
  --lighter-yellow-94: hsl(38, 70%, 94%);
  --lighter-yellow-92: hsl(38, 90%, 92%);
  --lighter-yellow-87: hsl(38, 35%, 87%);
  --base-yellow: hsl(38, 100%, 56%);
  --darker-yellow-27: hsl(38, 98%, 27%);
  --darker-yellow-23: hsl(38, 15%, 23%);

  /* green palette */
  --lighter-green-93: hsl(166, 56%, 93%);
  --lighter-green-88: hsl(166, 70%, 88%);
  --lighter-green-82: hsl(166, 50%, 82%);
  --base-green: hsl(166, 100%, 42%);
  --darker-green-33: hsl(166, 100%, 33%);
  --darker-green-21: hsl(166, 100%, 21%);
  --darker-green-18: hsl(166, 30%, 18%);

  /* neutral palette */
  --lighter-neutral-99: hsl(216, 0%, 99%);
  --lighter-neutral-96: hsl(216, 35%, 96%);
  --lighter-neutral-95: hsl(216, 45%, 95%);
  --lighter-neutral-92: hsl(216, 35%, 92%);
  --lighter-neutral-85: hsl(216, 20%, 85%);
  --base-neutral: hsl(216, 30%, 75%);
  --darker-neutral-45: hsl(216, 22%, 45%);
  --darker-neutral-25: hsl(216, 35%, 25%);

  /* blue palette */
  --lighter-blue-96: hsl(216, 100%, 96%);
  --lighter-blue-89: hsl(216, 100%, 89%);
  --lighter-blue-82: hsl(216, 100%, 82%);
  --base-blue: hsl(216, 100%, 56%);
  --base-blue-transparent: hsl(216 100% 56% / 0.2);
  --darker-blue-43: hsl(216, 85%, 43%);
  --darker-blue-29: hsl(216, 70%, 29%);
  --darker-blue-25: hsl(216, 40%, 25%);
  --darker-blue-20: hsl(216, 80%, 20%);
  --darker-blue-19: hsl(216, 69%, 19%);
  --darker-blue-17: hsl(216, 70%, 17%);
  --darker-blue-15: hsl(216, 70%, 15%);
  --darker-blue-13: hsl(216, 90%, 13%);
  --darker-blue-10: hsl(216, 69%, 10%);

  /* purple palette */
  --lighter-purple-96: hsl(274, 56%, 96%);
  --lighter-purple-93: hsl(274, 67%, 93%);
  --base-purple: hsl(274, 87%, 63%);
  --darker-purple-29: hsl(274, 40%, 29%);

  /* red palette */
  --lighter-red-94: hsl(339, 64%, 94%);
  --lighter-red-89: hsl(339, 65%, 89%);
  --lighter-red-87: hsl(339, 41%, 87%);
  --lighter-red-83: hsl(339, 41%, 83%);
  --lighter-red-83-transparent: hsl(339 41% 83% / 0.4);
  --base-red: hsl(339, 98%, 61%);
  --darker-red-35: hsl(339, 75%, 35%);
  --darker-red-25: hsl(339, 45%, 25%);

  /* misc */
  --base-black-transparent: hsla(215, 19%, 57%, 0.15);
}
