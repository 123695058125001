.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10001;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-container.modal-containerHidden {
  z-index: -1;
}

.modal-panel {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, 0, 0);
  transition: transform 500ms cubic-bezier(0, 0, 0.25, 1),
    opacity 500ms cubic-bezier(0, 0, 0.25, 1);
  width: 400px;
  height: fit-content;
  min-height: 300px;
  z-index: 10002;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-panelHidden {
  transform: translate3d(0, -100px, 0);
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: -1;
}

.modal-panel > header {
  display: flex;
  justify-content: center;
  font-family: 'ArticulatCF-Bold';
  font-size: 24px;
  height: 60px;
  border-bottom: 1px solid #dee2e6;
  padding: 20px 0 0 0;
}

.modal-panel > section {
  padding: 10px;
  font-family: 'ArticulatCF-Medium';
  padding: 0 20px 20px;
}

.modal-panel > footer {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #dee2e6;
  padding: 10px;
}
