@font-face {
  font-family: 'Fontawesome webfont';
  src: url('./fonts/fontawesome-webfont.woff2') format('woff2'),
    url('./fonts/fontawesome-webfont.woff') format('woff'),
    url('./fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body,
#root,
#root > div,
.appContentContainer {
  height: 100% !important;
}

.fontawesome {
  font-family: 'Fontawesome webfont', sans-serif;
}

body {
  font-family: 'ArticulatCF-DemiBold';
  background: var(--lighter-neutral-99);
  color: #3b415b;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

a:link {
  color: #3b415b;
}

a:visited {
  color: #3b415b;
}

p::selection,
span::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
a::selection,
div::selection,
button::selection {
  background-color: var(--base-blue);
  color: var(--lighter-neutral-99);
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

::placeholder {
  color: lightgrey;
}

/* COMMON STYLES */
.btnPrimary {
  background-color: var(--darker-blue-43);
  color: white;
}

.btnPrimary:hover {
  background-color: #0056bc;
}

.btnDanger:hover {
  background-color: var(--base-red);
  color: white;
}

/* page STYLES */
.pageContainer {
  height: 100%;
}

.pageTitle {
  margin: 10px;
  font-size: 34px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-Medium';
}

.pageRuler {
  margin: 0 0 20px;
  padding-left: 5px;
  border: none;
  height: 1px;
  background: #e1e7ef;
}

.tableRuler {
  border-top: 1px solid #e1e7ef;
  padding-left: 5px;
  margin: 0 0 20px;
}

@keyframes tableRowFadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* page header */
.pageHeader {
  font-size: 26px;
  margin: 10px;
  align-items: flex-end;
}

.headerControl {
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

/* status selector */
.statusSelector .Select-multi-value-wrapper {
  display: flex;
}

.statusSelector .Select-value {
  display: flex;
  align-items: center;
}

.statusSelector .Select-input {
  opacity: 0;
}

.Select--single > .Select-control .Select-value {
  margin-right: 23px;
  font-weight: 600;
}

.statusSelector .Select-menu-outer {
  width: 190px;
}

.statusSelector .Select-option {
  color: var(--darker-green-33) !important;
}

.statusSelector .Select-option.is-focused {
  background-color: var(--lighter-green-82);
}

.statusSelector .Select-option.is-selected {
  background-color: var(--lighter-green-82);
}

.statusSelector .Select-option:nth-child(2) {
  color: var(--base-yellow) !important;
}

.statusSelector .Select-option:nth-child(2).is-selected {
  background-color: var(--lighter-neutral-92);
}

.statusSelector .Select-option:nth-child(2).is-focused {
  background-color: var(--lighter-neutral-92);
}

.statusSelector .Select-option:nth-child(3) {
  color: var(--base-red) !important;
}

.statusSelector .Select-option:nth-child(3).is-selected {
  background: var(--lighter-red-89);
}

.statusSelector .Select-option:nth-child(3).is-focused {
  background: var(--lighter-red-89);
}

.statusSelectorArrow {
  font-family: 'Fontawesome webfont';
  color: #fff;
}

.gm-style {
  font-family: 'ArticulatCF' !important;
}

/* flex style */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* individual page */
.row__data,
.row__header {
  font-size: 18px;
}

.row__header {
  flex: 1;
  height: 40px;
  letter-spacing: 1px;
  color: var(--base-neutral);
  font-family: 'ArticulatCF-Heavy';
}
.row__data {
  flex: 1;
  align-items: center;
  color: var(--darker-neutral-25);
}

.row__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86px;
}

.view__info-row {
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e1e7ef;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 20px;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  /* status selector */
  .statusSelector {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
    font-size: 14px;
  }
  .statusSelector .Select-menu-outer {
    margin-left: -5px;
    height: 144px;
    width: 150px;
    padding: 6px;
    font-size: 14px;
  }
  /* page header */
  .pageHeader {
    font-size: 20px;
    margin: 0;
  }
  .pageTitle {
    margin: 8px;
    font-size: 24px;
  }
  .headerControl {
    height: 70px;
  }
  .pageRuler {
    margin: 0 0 20px 0;
  }

  /* individual view data row */
  .view__info-row {
    font-size: 14px;
    padding-bottom: 10px;
    margin: 0 10px;
  }
  .row__data,
  .row__header {
    font-size: 14px;
  }
  .row__item {
    height: 60px;
  }
}

@media screen and (max-width: 767px) {
  .view__info-row {
    flex-direction: column;
  }
}
