.tooltip {
  display: flex;
}

.tooltip-icon img {
  width: 24px;
  margin-top: 5px;
}
.tooltip-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
}
.tooltip-title {
  color: #3b415b;
  font-size: 18px;
  font-family: 'ArticulatCF-DemiBold';
}

.tooltip-message {
  color: #7c8da5;
  font-size: 17px;
  font-family: 'ArticulatCF-DemiBold';
}

/* Theme white for Tippy.js */

.tippy-tooltip {
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.tippy-tooltip.white-theme .tippy-content {
  background-color: white;
  color: black;
}

.tippy-tooltip.white-theme .tippy-backdrop {
  background-color: white;
}

.tippy-popper[x-placement^='top']
  [data-animation='shift-away'][data-state='visible'] {
  box-shadow: 0 4px 10px rgba(21, 70, 112, 0.16);
}
