.chart-bottom-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  color: var(--base-neutral);
  margin-top: -18px;
}
.chart-bottom-line img {
  width: 10px;
}
.chart-bottom-line span {
  margin-left: 5px;
}
.chart-bottom-line-start {
  align-self: flex-start;
}
.chart-bottom-line-end {
  align-self: flex-end;
}
.chart-active-bar {
  opacity: 1 !important;
}
.chart-content {
  display: flex;
  flex: 1;
}

.chart-content .custom-tooltip {
  padding: 1px;
  width: 120px;
  height: 70px;
  border-radius: var(--border-radius);
  background-color: white;
  box-shadow: 0 3px 6px #30568914;
}

.chart-content .custom-tooltip hr {
  width: 100%;
  background: var(--lighter-neutral-92);
  height: 1px;
  border: none;
  margin-top: 0;
  margin-bottom: 10px;
}

.chart-content .custom-tooltip .activeDate {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  font-family: 'ArticulatCF-Bold';
  color: var(--base-blue);
  padding-bottom: 4px;
  white-space: nowrap;
}

.chart-content .custom-tooltip img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-left: 8px;
}

.chart-content .custom-tooltip .row {
  display: flex;
  flex-direction: row;
}

.chart-content .custom-tooltip .extraMetrics {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'ArticulatCF-Bold';
  font-size: 14px;
  background-color: var(--lighter-neutral-96);
  color: var(--darker-neutral-45);
  padding: 4px;
  /* padding: 4px 8px 4px 8px; */
}

.chart-content .custom-tooltip .extraMetric {
  color: var(--base-blue);
}
