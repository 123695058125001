.issue__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  min-height: 30px;
  align-items: center;
}

.issue__header p {
  margin: 0;
}

.issueLabel {
  justify-content: center;
  align-items: center;
  font-size: 34px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-Medium';
}

.issueLabel img {
  width: 30px;
  margin-right: 20px;
}

.issueDate {
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 19px;
  color: var(--base-neutral);
}

.issueDate p {
  padding: 6px 16px;
  margin-left: 20px;
  font-size: 18px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-ExtraBold';
  border-radius: 20px;
  background: var(--lighter-neutral-92);
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .issue__header {
    margin-bottom: 10px;
  }
  .issueLabel {
    font-size: 24px;
  }
  .issueLabel img {
    width: 20px;
    margin-right: 10px;
  }
  .issueDate {
    font-size: 16px;
  }
  .issueDate p {
    padding: 4px 10px;
    margin-left: 10px;
    font-size: 14px;
  }
}
