.textAreaInput {
  outline: none;
  border-radius: var(--border-radius);
  border: 1px solid #e5ecf2;
  background: white;
  padding: 10px;
  width: stretch;
}

.textAreaInput::placeholder {
  color: var(--base-neutral);
  font-size: 17px;
  font-family: 'ArticulatCF-Medium';
}
