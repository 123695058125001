.upsertAudit-content {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .upsertAudit-content {
    width: 300px;
  }
}
