.vcr_preview {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 20px;
  border-radius: 12px;
  background: var(--lighter-neutral-99);
  width: fit-content;
  margin: 0 auto;
}

.downloadBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  margin-bottom: -40px;
  margin-left: 370px;
  transition: margin-left 0.4s ease-in-out;
}

.downloadBtnContainer p {
  margin: 0;
  margin-left: 20px;
  font-size: 28px;
  font-family: 'ArticulatCF-Heavy';
}

.printVCR__title {
  height: 60px;
  text-align: center;
  background-color: #3b415b;
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  font-size: 18px;
  font-family: 'ArticulatCF-Heavy';
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  padding: 0 20px;
}

.printVCR__title p {
  margin-left: 80px;
}

.RMS_logo {
  font-family: 'ArticulatCF-Text';
}

.RMS_logo,
.INTRMODL_logo {
  font-size: 14px;
}

.INTRMODL_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.INTRMODL_logo img {
  margin-right: 10px;
  width: 20px;
}

.first_row,
.second_row {
  justify-content: space-between;
}

.first_row .input-container + .input-container,
.second_row .input-container + .input-container {
  margin-left: 42px;
}

.printVCR_issues-left-title {
  font-size: 14px;
  font-family: 'ArticulatCF-DemiBold';
}

.printVCR_issues-column {
  width: calc(100% - 20px);
  margin-right: 20px;
}

.vehicle_images {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
}

.vehicle_images .overlayContainer .vehicleImageStyle {
  animation: none;
}

.vehicle_images .overlayContainer .vehicleImageStyle + #canvasOverlay {
  animation: none;
}

.vehicle_comment {
  font-size: 10px;
  font-family: 'ArticulatCF-ExtraBold';
  border-top: 2px solid #3b415b;
  border-bottom: 2px solid #3b415b;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
}

.repairedDate {
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-family: 'ArticulatCF-DemiBold';
  padding-left: auto;
}

.repairedDate__input {
  width: 140px;
  height: 30px;
  margin-left: 20px;
  border-radius: 40px;
  border: 1px solid #e1e7ef;
  background-color: #f8fafd;
}

.vcr__comment .inputForm__input {
  height: 100px;
}

/* print form iframe */
.appContentContainer .print-vcr {
  height: 100%;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .downloadBtnContainer {
    padding: 20px 30px 8px 0px;
    margin-bottom: 0;
  }
  .downloadBtnContainer p {
    font-size: 24px;
  }
  .printBtn {
    font-size: 16px;
    height: 44px;
  }
}
