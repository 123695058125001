.progressBar {
  position: relative;
  height: 30px;
  margin-bottom: 40px;
}

.progressBar-circles {
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10%;
}

.progressBar-circle {
  height: 30px;
  width: 30px;
  background-color: #d8e2eb;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  border: 3px solid transparent;
}

.progressBar-circle.selected {
  width: 30px;
  height: 30px;
  box-shadow: 0 4px 5px rgba(29, 121, 255, 0.4);
  background-color: var(--base-blue);
  border: none;
}

.progressBar-bars {
  display: flex;
  width: 98%;
  position: absolute;
  top: 12px;
  left: 1%;
  padding: 0 10%;
}

.progressBar-bar {
  height: 6px;
  background-color: #d8e2eb;
}

.progressBar-bar.selected {
  background-color: var(--base-blue);
  animation: 0.4s barAnimation;
}

@keyframes barAnimation {
  0% {
    background-color: #d8e2eb;
  }
  100% {
    background-color: var(--base-blue);
  }
}
