.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding: 5%;
  cursor: pointer;
  width: fit-content;
  border: none;
  border-radius: var(--element);

  font-size: 18px;
  color: white;
  background-color: var(--darker-blue-43);
  transition: all 0.2s ease-in-out;
  margin-top: 15px;
}

.button img {
  width: 18px;
  height: 18px;
}

.button.small {
  font-size: 16px;
  padding: 4%;
}

.button.small img {
  width: 18px;
  height: 18px;
}

.button:hover {
  background-color: rgba(27, 78, 255, 0.5);
}

.button.active:hover {
  background-color: var(--darker-blue-43);
}

button:focus,
button:active,
button:focus:active {
  box-shadow: none;
  outline: none;
}

.button-step-previous,
.button-step-next {
  background-color: var(--lighter-neutral-99);
  border: 1px solid #d8e2eb;
  color: var(--darker-neutral-45);
  padding: 0 8%;
  height: 54px;
}

.button-step-previous:hover,
.button-step-next:hover {
  color: white;
}

.button-step-next {
  margin-left: auto;
  background-color: #cae3fc;
  border-color: #cae3fc;
  color: var(--base-blue);
}

.button-step-next:hover {
  background-color: var(--base-blue);
  color: white;
}
