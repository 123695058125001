.tagsBox {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: flex-start;
  overflow-y: auto;
}

.tagsBox-tag {
  border: 1px solid var(--lighter-blue-96);
  border-radius: 17px;
  color: var(--base-blue);
  padding: 4px 8px;
  margin: 4px;
  font-size: 12px;
  cursor: pointer;
}

.tagsBox.isReadOnly .tagsBox-tag {
  cursor: default;
  pointer-events: none;
}

.tagsBox-tag.selected {
  background-color: var(--base-blue);
  color: white;
}
@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .tagsBox {
    min-height: 50px;
  }
  .tagsBox-tag {
    outline: none;
  }
}
