.issueTable {
  min-width: 234px;
  border-radius: var(--border-radius);
  border: 1px solid #e1e7ef;
  background-color: #f8fafd;
  padding: 8px;
  margin: 4px 0 14px;
}

.issueTable_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: var(--border-radius);
  background-color: white;
  height: 20px;
  padding: 4px 8px;
  font-size: 10px;
  font-family: 'ArticulatCF-Heavy';
}

.issueTable_header div:first-child {
  width: 70%;
  align-items: baseline;
}

.issueTable_header div {
  width: 15%;
}

.issueTable_header p {
  font-family: 'ArticulatCF-Bold';
  margin: 0;
  font-size: 8px !important;
  color: var(--darker-neutral-45);
}

.issueTable_row {
  font-size: 10px;
  font-family: 'ArticulatCF-DemiBold';
  border-bottom: 1px solid #e6ebf2;
  padding: 2px 0;
  margin: 0 10px;
}

.issueTable_row div {
  white-space: nowrap;
}

.issueTable_row:last-child {
  border: none;
}

.issueTable_row div:first-child {
  width: 70%;
}

.issueTable_row div {
  width: 15%;
}
