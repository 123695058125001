.yardSummary .pageHeader .title {
  justify-content: space-between;
  margin: 8px;
  align-items: flex-start;
  font-family: 'ArticulatCF-Medium';
  font-size: 34px;
  color: var(--darker-neutral-25);
}

.noDataAlert {
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-family: 'ArticulatCF-ExtraBold';
}

.metric-label {
  margin: 0 5px;
  width: 1.3rem;
  height: 1.2rem;
  object-fit: contain;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .yardSummary .pageHeader .title {
    margin: 0 10px 10px;
    font-size: 24px;
    align-items: center;
  }

  /* date toggle */
  .yardSummary .date-controller {
    margin: 20px 10px 10px;
  }

  /* info card */
  .infoCard {
    min-width: 146px;
  }

  /* chart */
  .summary__chart .chart__label {
    font-size: 20px;
  }
  .chartAxis__label {
    left: 30px;
    right: 30px;
    font-size: 12px;
  }
  .chartAxis__label .label__date {
    padding: 4px 8px;
    margin-left: 6px;
  }
}
