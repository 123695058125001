.tab {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.tab.isActive {
  border-bottom: 3px solid var(--base-blue);
}

.tab img {
  height: 20px;
}

.tab span {
  margin-left: 5px;
}
