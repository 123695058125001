.notesBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'ArticulatCF-Medium';
}

.notesBox-notes {
  min-height: calc(100% - 60px);
  overflow-y: scroll;
  max-height: 280px;
}

.notesBox-note {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.notesBox-message {
  font-size: 16px;
  padding: 10px 30px;
  background-color: var(--lighter-neutral-96);
  width: 100%;
  border-radius: var(--border-radius);
}

.notesBox-icon-text {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  color: var(--darker-neutral-45);
}

.notesBox-icon-text img {
  width: 15px;
  margin-right: 10px;
}

.notesBox-send-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .notesBox-notes {
    min-height: 68px;
    max-height: 98px;
  }
  .notesBox-note {
    margin-bottom: 0px;
  }
  .notesBox-icon-text {
    padding: 6px;
  }
  .notesBox-message {
    padding: 8px 30px;
  }
}
