.cardItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--lighter-neutral-96);
  border-radius: var(--border-radius);
  background: var(--lighter-neutral-99);
  min-width: 30%;
}

.cardItem-icon {
  border-radius: 50%;
  background: var(--lighter-neutral-96);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.green-circle-bg {
  background: var(--lighter-neutral-96);
}

.red-circle-bg {
  background: var(--lighter-neutral-96);
}

.yellow-circle-bg {
  background: var(--lighter-neutral-96);
}

.cardItem-icon img {
  width: 24px;
  height: 24px;
}

.cardItem-title {
  color: VAR(--darker-neutral-45);
  font-size: 15px;
  font-family: 'ArticulatCF-Medium';
}

.cardItem-value {
  font-size: 55px;
  font-family: 'ArticulatCF-Bold';
  color: var(--darker-neutral-25);
}

@media screen and (max-width: 1240px), screen and (max-height: 800px) {
  .cardItem {
    padding: 14px;
  }
  .cardItem-icon {
    width: 50px;
    height: 50px;
    margin-right: 14px;
  }
  .cardItem-icon img {
    width: 20px;
    height: 20px;
  }
  .cardItem-title {
    font-size: 13px;
  }
  .cardItem-value {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .cardItem {
    width: 100%;
  }
}
